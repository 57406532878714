<script>

export default {
  name: "external-stat-card",
  components: {},
  props: {
    title: {
      type: String,
      default: "Utilisateurs",
    },
    amount: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "message",
    },
  },
  data() {
    return {

    }
  },
  methods: {
    convertFormatedStringToNumber(amount){
      return parseInt(amount.replace(/\s+/g, ''), 10)
    },
  },
  computed: {
    convertedNumber(){
      return this.convertFormatedStringToNumber(this.amount)
    },
    isCardColorChange() {
      return this.convertedNumber < 1000
    },

    isCardWarning() {
      return this.convertedNumber < 1000 && this.convertedNumber > 500
    },
    isCardDanger() {
      return this.convertedNumber < 500 && this.convertedNumber > 0
    }
  }
}
</script>

<template>
  <div class="col-xl-4 col-md-6 ">
    <!-- card -->
    <div class="card card-animate" :class="{'bg-warning': isCardWarning, 'bg-danger': isCardDanger}">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <p class="text-uppercase fw-medium mb-0" :class="{'text-white': isCardColorChange, 'text-muted': !isCardColorChange}">
              {{ title }}
            </p>
          </div>
        </div>
        <div class="d-flex align-items-end justify-content-between mt-4">
          <div>
            <h4 class="fs-22 fw-semibold ff-secondary mb-4" :class="{'text-white': isCardColorChange}">
              {{amount}}
            </h4>
<!--            <router-link :to="'#'" class="text-decoration-underline " :class="{'text-white-50': isCardColorChange}">Voir plus</router-link>-->
          </div>
          <div class="avatar-sm flex-shrink-0" >
              <span  class="avatar-title bg-soft-light rounded fs-3" >
                  <i :class="`bx bx-${icon} ${ isCardColorChange ? 'text-white' : 'bg-soft-light'}`"></i>
              </span>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
</template>

<style scoped>

</style>