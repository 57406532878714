<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import StatCard from "@/components/dashboard/StatCard.vue";
import API from "@/plugins/axios-method";
import LineChart from "../../components/charts/line-chart.vue";
import ExternalStatCard from "@/components/ExternalStatCard.vue";
import DataLoader from "@/components/data-loader.vue";

export default {
  page: {
    title: "Starter",
    meta: [{ name: "description", content: 'Application Dashboard' }],
  },
  components: {
    DataLoader,
    ExternalStatCard,
    StatCard,
    Layout,
    PageHeader,
    LineChart,
},
  data() {
    return {
      year: new Date().getFullYear(),
      user_count: 0,
      total_savings: 0,
      total_saving_fees: 0,
      total_registration_fees: 0,
      total_registration: 0,
      total_transactions: 0,
      total_members: 0,
      total_commercials: 0,
      total_supervisors: 0,
      total_coordinators: 0,
      campost_payment_balance: "0",
      sms_credit: "0",
      url : '',
      savingPerMonthChart: {
        labels: [],
        data: []
      },
      savingsPerWeekChart: {
        labels: [],
        data: []
      },
      savingsPerDayChart: {
        labels: [],
        data: []
      },
      showDataLoader: true
    };
  },
  mounted() {
    this.url = '/statistic/' + this.$store.getters['auth/userId'] + '?year=' + this.year
    this.getStats()
  },
  methods: {
    formatNumber(number = 0){
      return number.toLocaleString()
    },
    getStats(){
      API.get(this.url)
          .then(response => {
            if (response.status === 200){
              const statistics = response.data
              this.user_count = this.formatNumber(statistics.users)
              this.total_savings = this.formatNumber(statistics.deposit)
              this.total_transactions = this.formatNumber(statistics.transactions)
              this.total_members = this.formatNumber(statistics.members)
              this.total_commercials = this.formatNumber(statistics.commercials)
              this.total_supervisors = this.formatNumber(statistics.supervisors)
              this.total_coordinators = this.formatNumber(statistics.coordinators)
              this.total_registration = this.formatNumber(statistics.registration)
              this.total_saving_fees = this.formatNumber(statistics.contributionNetworkFees)
              this.total_registration_fees = this.formatNumber(statistics.subscriptionNetworkFees)
              this.campost_payment_balance = statistics.campostPaymentBalance
              this.sms_credit = statistics.smsCredit
              const savingPerMonth = statistics.transactionsPerMonth
              const savingPerWeek = statistics.transactionsPerWeek
              const savingPerDay = statistics.transactionsPerDay

              for (const day in savingPerDay){
                this.savingsPerDayChart.labels.push(day)
                this.savingsPerDayChart.data.push(savingPerDay[day])
              }

              for (const week in savingPerWeek){
                this.savingsPerWeekChart.labels.push(week)
                this.savingsPerWeekChart.data.push(savingPerWeek[week])
              }
              for (const month in savingPerMonth) {
                this.savingPerMonthChart.labels.push(month)
                this.savingPerMonthChart.data.push(savingPerMonth[month])
              }
            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader 
      :show-add-button="false" 
      :title="'Dashboard'" 
      :show-search="false"
    />
    <section>
      <div class="row">
        <div class="col-12">
          <h5 class="text-decoration-underline mb-3 pb-1">Statistiques Générales</h5>
        </div>
      </div>
      <data-loader v-if="showDataLoader"/>
      <div class="row" v-else-if="!showDataLoader">
        <StatCard
            :amount="campost_payment_balance"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'solde campost payment'"
        />
        <StatCard
          :amount="total_registration"
          :is-money="true"
          :is-increase="true"
          :percentage="65"
          :title="'Frais d\'Inscriptions'"
          :to-link="'/admin/transaction/subscription'"
        />
        <StatCard
            :amount="total_savings"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Epargne Totale'"
            :to-link="'/admin/transaction/savings'"
        />
        <StatCard
          :title="'Frais sur insciption'"
          :to-link="'/admin/transaction/subscription'"
          :amount="total_registration_fees"
          :is-increase="false"
          :percentage="35"
          is-money
        />
        <StatCard
            :title="'Frais sur epargnes'"
            :to-link="'/admin/transaction/savings'"
            :amount="total_saving_fees"
            :is-increase="false"
            :percentage="35"
            is-money
        />
        <StatCard
            :title="'Coordinateurs'"
            :to-link="'/admin/coordinators'"
            :amount="total_coordinators"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Superviseurs'"
            :to-link="'/admin/supervisors'"
            :amount="total_supervisors"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Commerciaux'"
            :to-link="'/admin/commercials'"
            :amount="total_commercials"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Adhérents'"
            :to-link="'/admin/members'"
            :amount="total_members"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <ExternalStatCard
          :amount="sms_credit"
          :icon="'message'"
          :title="'sms restant'"
        />
      </div>
    </section>
    <section v-if="!showDataLoader">
      <div class="row">
        <div class="col">
          <LineChart
              :labels="savingPerMonthChart.labels"
              :data="savingPerMonthChart.data"
              :title="'Epargne en ' + year"
              :year="year"
          />
          <LineChart
              :labels="savingsPerDayChart.labels"
              :data="savingsPerDayChart.data"
              :title="'Epargne par jour en ' + year"
              :year="year"
          />
<!--          <LineChart-->
<!--              v-if="!dataLoader"-->
<!--              :labels="savingsPerWeekChart.labels"-->
<!--              :data="savingsPerWeekChart.data"-->
<!--              :title="'Epargne par semaine en ' + year"-->
<!--              :year="year"-->
<!--          />-->
        </div>
      </div>
    </section>
  </Layout>
</template>